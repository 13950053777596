import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import ScheduleHeader from '../components/Header/scheduleHeader';
import bg from '../assets/images/bg.png';

const SignUp = lazy(() => import('../pages/SignUp'));
const HealthInfo = lazy(() => import('../pages/HealthInfo'));
const WaitListComponent = lazy(() => import('../pages/WaitList'));
const PatientWaitListComponent = lazy(() => import('../pages/PatientWaitList'));
const FinalStep = lazy(() => import('../pages/FinalStep'));
const GetStarted = lazy(() => import('../pages/GetStarted'));
const PersonalInfo = lazy(() => import('../pages/PersonalInfo'));
const Appointment = lazy(() => import('../pages/Appointment'));
const Reimbursment = lazy(() => import('../pages/Reimbursment'));
const Admin = lazy(() => import('../pages/Admin'));

const ScheduleRoutes = () => {
  return (
    <React.Fragment>
      <ScheduleHeader />
      <div className="content" style={{ backgroundImage: `url(${bg})` }}>
        <Switch>
          <Route path="/schedule" exact>
            <SignUp
              title="Sign Up for Your Vaccine"
              subTitle="Basic Information"
              route="schedule"
              isPassword={true}
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule/signup">
            <SignUp
              title="Sign Up for Your Vaccine"
              subTitle="Basic Information"
              route="schedule"
              isPassword={true}
            />
          </Route>
        </Switch>
        <Switch>
          <Route
            path="/schedule/health_information/:id"
            component={HealthInfo}
          />
        </Switch>
        <Switch>
          <Route path="/schedule/waitlist" component={WaitListComponent} />
        </Switch>
        <Switch>
          <Route
            path="/schedule/patient_waitlist"
            component={PatientWaitListComponent}
          />
        </Switch>
        <Switch>
          <Route path="/schedule/final-step">
            <FinalStep
              title=""
              subTitle="You're All Set"
              description="For"
              idTxt="Customer ID"
              codeTxt="Confirmation Code"
              isReminderBtn={false}
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule/get-started">
            <GetStarted isProvider={false} />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule/personal-info" component={PersonalInfo} />
        </Switch>
        <Switch>
          <Route path="/schedule/appointment" component={Appointment}>
            <Appointment
              title="Let's Get You Scheduled"
              subTitle="in text and email."
              idTxt="Customer ID"
              codeTxt="Confirmation Code"
              route="schedule"
              cancelTxt="Modify"
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule/reimbursment" component={Reimbursment} />
        </Switch>
        <Switch>
          <Route path="/schedule/admin" component={Admin} />
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default ScheduleRoutes;

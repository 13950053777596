import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LoadingPage from './components/Loading';

import Routes from './routes';

const App = () => (
  <Router>
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Routes />
      </Switch>
    </Suspense>
  </Router>
);

export default App;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Navbar, Nav } from 'react-bootstrap';

const Sidebar = (props) => {
  const location = useLocation()
  const [title, setTitle] = useState()

  useEffect(() => {
    const checkApp = location.pathname.split("/")
    const checkOthers = checkApp[2]
    if (checkApp.includes("appointment")) {
      setTitle("Appointment")
    } else if (checkOthers === 'landing') {
      setTitle("Schedule")
    } else {
      const capitalTitle = checkOthers.charAt(0).toUpperCase() + checkOthers.slice(1)
      setTitle(capitalTitle)
    }
  }, [location])

  return (
    <Navbar className="sidebar" collapseOnSelect expand="sm">
      <h4>{ title }</h4>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="align-self-start">
        <Nav className="sidebar-links mr-auto flex-column">
          <NavLink to="/appointment">View Appointments</NavLink>
          <NavLink to="/schedule-provider/landing">Schedule</NavLink>
          <NavLink to="/schedule-provider/reimbursment">Reimbursements</NavLink>
          <NavLink to="/schedule-provider/admin">Admin</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Sidebar;

import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../components/Header/index';

const Landing = lazy(() => import('../pages/Landing'));

const LandingRoute = () => {
  return (
    <React.Fragment>
      <Header />
      <Switch>
        <Route exact path='/' component={Landing} />
      </Switch>
    </React.Fragment>
  );
};

export default LandingRoute;

import { APPOINTMENTS, APPOINTMENT_ERROR } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case APPOINTMENTS:
      return {
        ...state,
        appointment: action.payload,
      };

    case APPOINTMENT_ERROR:
      return {
        ...state,
        appointment: action.payload,
      };
    default:
      return state;
  }
};

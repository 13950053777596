import { APPOINTMENTS, APPOINTMENT_ERROR, LANDING, MODAL } from './types';
import * as API from '../api';
import _ from 'lodash';
import moment from 'moment';

export const indexAction = (payload) => {
  return {
    type: LANDING,
    payload
  }
}

export const modalAction = (payload) => {
  return {
    type: MODAL,
    payload
  }
}

export const appointment = (params=new Date()) => async dispatch => {
  try {
    const { data } = await API.getAppointments(params);
    let converData = await convertToGroupBy(data);
    return { 
      type: APPOINTMENTS,
      appointments: converData
    }
  } catch (error) {
    console.error('Get Appointment', error);
    return {
      type: APPOINTMENT_ERROR,
      errors: error,
    }
  }
}

function convertToGroupBy(data){
  let result = _(data)
  .groupBy(v => moment(v.book).format('h:mm a'))
  .mapValues(v => _.map(v))
  .value();
  return result
}

import axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;
const TOKEN = process.env.REACT_APP_USER_TOKEN;

const api = axios.create({
  baseURL: BASEURL,
  headers: {
    'content-type': 'application/json',
    'Authorization': `Basic ${TOKEN}`
  },
});

export const setAuthorizationToken = (token) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Basic ${token}`;
  } else {
    delete api.defaults.headers.common.Authorization;
  }
};

function request({ method = 'get', url, params, data, cancelToken }) {
  return api.request({
    method,
    url,
    params,
    data,
    cancelToken,
  });
}

export function get(url, params, cancelToken) {
  return request({
    method: 'get',
    url,
    params,
    cancelToken,
  });
}

export function post(url, data, cancelToken) {
  return request({
    method: 'post',
    url,
    data,
    cancelToken,
  });
}

export function put(url, data, cancelToken) {
  return request({
    method: 'put',
    url,
    data,
    cancelToken,
  });
}

export function patch(url, data, cancelToken) {
  return request({
    method: 'patch',
    url,
    data,
    cancelToken,
  });
}

export function remove(url, data, cancelToken) {
  return request({
    method: 'delete',
    url,
    data,
    cancelToken,
  });
}

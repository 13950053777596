import React, { Component } from 'react';
import Header from '../Header';
import Spinner from 'react-bootstrap/Spinner';

export default class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page-wrapper">
        <Header />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '80vh' }}
        >
          <Spinner animation="border" role="status" className="d-block mx-auto">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }
}

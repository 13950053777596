import history from '../common/history';
import configureStore from './config';

import allReducer from '../reducers/rootReducer';

const initialState = window.initialReduxState;
const { store } = configureStore(history, initialState, allReducer);

export default {
  store,
  history,
};

import React, { useState, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import LandingRoute from './landing';
import ScheduleRoutes from './schedule';
import ProviderRoutes from './provider';
import AppointmentRoutes from './appointment';

const Routes = () => {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingRoute />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule">
            <ScheduleRoutes />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider">
            <ProviderRoutes />
          </Route>
        </Switch>
        <Switch>
          <Route path="/appointment">
            <AppointmentRoutes />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Navbar, Button } from 'react-bootstrap';

import logo from '../../assets/images/logo1.png';

const ScheduleHeader = () => {
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector((state) => state.landing);

  return (
    <div className="header">
      <Navbar bg="white" variant="light" fixed="top">
        <Navbar.Brand
          style={{ color: 'lightgrey', fontSize: '12px', marginTop: '2px' }}
        >
          <Nav.Link className="text-secondary" href="/">
            <img
              src={logo}
              height="30"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Nav.Link>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link className="text-secondary" href="#home">
            |
          </Nav.Link>
          <Nav.Link
            className="text-secondary"
            href="#home"
            style={{ fontSize: '12px' }}
          >
            POWERED BY <strong>Vax</strong>Werks
          </Nav.Link>
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          {isSignedIn ? (
            <Button variant="light">Logout</Button>
          ) : (
            <Nav className="justify-content-end">
              <Button variant="light">Sign In</Button>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default ScheduleHeader;

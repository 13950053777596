import React, { lazy, useState, useEffect } from 'react';
import { Switch, Route, useLocation, NavLink } from 'react-router-dom';

import ScheduleHeader from '../components/Header/scheduleHeader';
import Sidebar from '../components/Sidebar';
import bg from '../assets/images/bg.png';

const Login = lazy(() => import('../pages/Login'));
const Landing = lazy(() => import('../pages/Provider'));
const SignUp = lazy(() => import('../pages/SignUp'));
const HealthInfo = lazy(() => import('../pages/HealthInfo'));
const WaitList = lazy(() => import('../pages/WaitList'));
const PatientWaitList = lazy(() => import('../pages/PatientWaitList'));
const Appointment = lazy(() => import('../pages/Appointment'));
const FinalStep = lazy(() => import('../pages/FinalStep'));
const GetStarted = lazy(() => import('../pages/GetStarted'));
const Reimbursment = lazy(() => import('../pages/Reimbursment'));
const Admin = lazy(() => import('../pages/Admin'));
const AdminFrame = lazy(() => import('../pages/AdminFrame'));
const LandingWalkin = lazy(() => import('../pages/LandingWalkin'));
const PersonalInfo = lazy(() => import('../pages/PersonalInfo'));
const VaxCard = lazy(() => import('../pages/VaxCard'));

const scheduleColumns = [
  {
    dataField: 'first',
    text: 'FIRST NAME',
    sort: true,
  },
  {
    dataField: 'last',
    text: 'LAST NAME',
    sort: true,
  },
  {
    dataField: 'ipid',
    text: 'PATIENT ID',
    sort: true,
  },
  {
    dataField: 'code',
    text: 'CONFIRMATION',
    sort: true,
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: '',
    formatter: (cellContent, row) => {
      return <NavLink to='/schedule-provider/appointment/'>schedule</NavLink>;
    },
  },
];

const checkInColumns = [
  {
    dataField: 'first',
    text: 'FIRST NAME',
    sort: true,
  },
  {
    dataField: 'last',
    text: 'LAST NAME',
    sort: true,
  },
  {
    dataField: 'ipid',
    text: 'PATIENT ID',
    sort: true,
  },
  {
    dataField: 'code',
    text: 'CONFIRMATION',
    sort: true,
  },
  {
    dataField: 'df1',
    isDummyField: true,
    text: '',
    formatter: (cellContent, row) => {
      return <a href="/appointment/patientdetails">check-in</a>;
    },
  },
];

const ProviderRoutes = () => {
  const location = useLocation();
  const [isSidebar, setIsSidebar] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === '/schedule-provider' ||
      window.location.pathname === '/schedule-provider/'
    ) {
      setIsSidebar(false);
    } else {
      setIsSidebar(true);
    }
  }, [location]);

  return (
    <React.Fragment>
      <ScheduleHeader />
      {isSidebar && <Sidebar />}
      <div
        className={`schedule-provider${isSidebar && '-with-sidebar'} content p-0`}
        style={{backgroundImage: `url(${bg})`}}
      >
        <Switch>
          <Route path="/schedule-provider" exact>
            <Login route="/appointment" />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/landing" exact component={Landing} />
        </Switch>
        <Switch>
          <Route path="/schedule-provider/signup" exact>
            <SignUp
              title="Schedule a Customer"
              subTitle="Starting with their contact information"
              route="schedule-provider"
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/signup-wi" exact>
            <SignUp
              title="Customer Information"
              subTitle="Starting with their contact information"
              route="schedule-provider"
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/health_information/:id">
            <HealthInfo
              subTitle="Please ask the Customer the following questions."
              route="schedule-provider"
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/health_information_wi/:id">
            <HealthInfo
              subTitle="Please ask the Customer the following questions."
              route="schedule-provider"
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/waitlist" component={WaitList} />
        </Switch>
        <Switch>
          <Route
            path="/schedule-provider/patient_waitlist"
            component={PatientWaitList}
          />
        </Switch>
        <Switch>
          <Route path="/schedule-provider/appointment">
            <Appointment
              title="Scheduling"
              subTitle="to customer’s phone and e-mail."
              idTxt="Patient ID"
              codeTxt="Authorization Code"
              route="schedule-provider"
              cancelTxt="Cancel"
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/final-step">
            <FinalStep
              title="The customer is all set!"
              subTitle="The appointment has been scheduled for:"
              description=""
              idTxt="Patient ID"
              codeTxt="Confirmation Code"
              isReminderBtn={true}
            />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/get-started">
            <GetStarted isProvider={true} />
          </Route>
        </Switch>
        <Switch>
          <Route
            path="/schedule-provider/reimbursment"
            component={Reimbursment}
          />
        </Switch>
        <Switch>
          <Route path="/schedule-provider/admin" component={AdminFrame} />
        </Switch>
        <Switch>
          <Route
            path="/schedule-provider/landing-walkin"
            component={LandingWalkin}
          />
        </Switch>
        <Switch>
          <Route path="/schedule-provider/patient-lookup">
            <PersonalInfo columns={scheduleColumns} />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/patient-lookup-wi">
            <PersonalInfo columns={checkInColumns} />
          </Route>
        </Switch>
        <Switch>
          <Route path="/schedule-provider/vaxcard" component={VaxCard} />
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default ProviderRoutes;

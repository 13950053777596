import React, { Component } from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import { indexAction } from '../../actions/index';

import logo from '../../assets/images/logo_orange_sm.png';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Navbar bg="white" variant="light" fixed="top">
          <Navbar.Brand href="#home">
            <img
              src={logo}
              height="30"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link className="text-secondary" href="/schedule">
              Schedule Appointment
            </Nav.Link>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            {this.props.landing.isSignedIn ? (
              <Button
                onClick={() => this.props.indexAction(false)}
                variant="light"
              >
                Logout
              </Button>
            ) : (
              <Nav className="justify-content-end">
                <Nav.Link
                  href="/schedule-provider"
                  className="text-dark mr-sm-5"
                >
                  Provider
                </Nav.Link>
                <Button
                  onClick={() => this.props.indexAction(true)}
                  variant="light"
                >
                  Sign In
                </Button>
              </Nav>
            )}
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  indexAction: (payload) => dispatch(indexAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

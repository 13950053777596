import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import ScheduleHeader from '../components/Header/scheduleHeader';
import Sidebar from '../components/Sidebar';
import bg from '../assets/images/bg.png';

const AppointmentGrid = lazy(() => import('../pages/Appointment/grid'));
const AppoinementDetailsFormWrapper = lazy(() =>
  import('../pages/PatientDetails')
);
const ConfirmAppointment = lazy(() => import('../pages/ConfirmAppointment'));
const SchedulerAppointment = lazy(() =>
  import('../pages/SchedulerAppointment')
);
const CheckedAppointment = lazy(() => import('../pages/CheckedAppointment'));

const AppointmentRoutes = () => {
  return (
    <React.Fragment>
      <ScheduleHeader />
      <Sidebar />
      <div
        className="appointment-container content p-0"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <Switch>
          <Route path="/appointment" exact>
            <AppointmentGrid />
          </Route>
        </Switch>
        <Switch>
          <Route path="/appointment/patientdetails">
            <AppoinementDetailsFormWrapper />
          </Route>
        </Switch>
        <Switch>
          <Route path="/appointment/confirm_appointment">
            <ConfirmAppointment />
          </Route>
        </Switch>
        <Switch>
          <Route path="/appointment/scheduler">
            <SchedulerAppointment />
          </Route>
        </Switch>
        <Switch>
          <Route path="/appointment/checked_appointment">
            <CheckedAppointment />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default AppointmentRoutes;

import {
  ADD_CUSTOMER,
  CUSTOMER_SUCCESS,
  CUSTOMER_ERROR,
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  customer: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER || CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.payload,
      };
    case CUSTOMER_ERROR:
      return {
        loading: false,
        customer: null,
        error: action.error
      }
    default:
      return state;
  }
};

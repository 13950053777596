import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import landing from './landingReducer';
import modal from './modalReducer';
import appointment from './appointmentReducer';
import customer from './customer';
import history from '../common/history';

const allReducers = combineReducers({
  landing,
  modal,
  customer,
  appointment,
  router: connectRouter(history),
});

export default allReducers;
